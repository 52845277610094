<template>
  <div>
    <r-page>
      <template #page-header>
        <h2 class="page-title has-text-color-page-title">
          Change Personal Info
        </h2>
        <r-third-level-menu />
      </template>

      <template #page-content>
        <b-table
          class="my-table"
          height="500"
          refs="myTable"
          :data="personalInfoList"
          :loading="isLoadPersonalInfoList"
          :per-page="perPage"
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @page-change="onPageChange"
          backend-sorting
          @sort="onSort"
          sticky-header
          :selected.sync="selected"
          focusabledd
        >
          <template slot="empty">
            <div
              style="height: 300px"
              class="is-flex is-align-items-center is-justify-content-center"
            >
              <div class="columns is-multiline has-text-centered">
                <div class="column is-12">
                  <b-icon
                    type="is-primary"
                    icon="account-details"
                    custom-size="mdi-48px"
                  ></b-icon>
                </div>
                <div class="column is-12">
                  <p class="has-text-grey-light is-size-5">
                    No personal info request
                  </p>
                </div>
              </div>
            </div>
          </template>

          <b-table-column
            field="id"
            label="Request ID"
            v-slot="props"
            centered
            sortable
            numeric
          >
            <span>
              {{ props.row.id }}
            </span>
          </b-table-column>

          <b-table-column
            field="updated_at"
            label="Date"
            v-slot="props"
            sortable
          >
            <span>
              {{ formatDate(props.row.updatedAt, 'LL') }}
            </span>
          </b-table-column>

          <b-table-column
            field="full_name"
            label="Employee Name"
            v-slot="props"
            sortable
          >
            <span class="is-capitalized">
              {{ props.row.requestedUser.fullName }}
            </span>
          </b-table-column>

          <b-table-column
            field="request_reason"
            label="Reason"
            v-slot="props"
            sortable
          >
            <span class="is-capitalized">
              {{ props.row.requestReason }}
            </span>
          </b-table-column>

          <b-table-column field="status" label="Status" v-slot="props" sortable>
            <span
              :class="`is-capitalized ${determineFontColorByStatus(
                props.row.status
              )}`"
            >
              {{ props.row.status }}
            </span>
          </b-table-column>
        </b-table>
      </template>

      <template #page-sidebar>
        <b-sidebar
          v-if="selected"
          position="static"
          right
          v-model="isSideBarOpen"
          fullheight
        >
          <r-card outlined>
            <div class="level">
              <div class="level-left">
                <div class="columns is-multiline">
                  <div class="column is-7-desktop is-12-tablet is-12-mobile">
                    <p class="has-text-weight-bold is-size-5">
                      Change Personal Info
                    </p>
                  </div>
                  <div class="column is-3-desktop is-12-tablet is-12-mobile">
                    <div
                      :class="`status-container p-1 has-text-centered ${determineBGColorByStatus(
                        selected.status
                      )}`"
                    >
                      <p
                        class="is-capitalized has-text-white is-size-6 has-text-weight-semibold"
                      >
                        {{ selected.status }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="level-right">
                <b-icon
                  icon="close"
                  class="office-pointer-click"
                  size="is-small"
                  @click.native="closeSidebar()"
                ></b-icon>
              </div>
            </div>
            <div class="columns is-multiline">
              <div class="column is-12">
                <p class="has-text-weight-bold">Current Data</p>
              </div>
              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                First Name
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{
                  selected.oldData.firstName ? selected.oldData.firstName : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Middle Name
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{
                  selected.oldData.middleName
                    ? selected.oldData.middleName
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Last Name
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{
                  selected.oldData.lastName ? selected.oldData.lastName : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Personal Email
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{
                  selected.oldData.personalEmail
                    ? selected.oldData.personalEmail
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Date of Birth
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{
                  selected.oldData.dateOfBirth
                    ? formatDate(selected.oldData.dateOfBirth, 'll')
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Place of Birth
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{
                  selected.oldData.placeOfBirth
                    ? selected.oldData.placeOfBirth
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Nationality
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{
                  selected.oldData.nationality
                    ? selected.oldData.nationality
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Address
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{ selected.oldData.address ? selected.oldData.address : '-' }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Current Address
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{
                  selected.oldData.currentAddress
                    ? selected.oldData.currentAddress
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Gender
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{ selected.oldData.gender ? selected.oldData.gender : '-' }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Personal Phone
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{
                  selected.oldData.personalPhone
                    ? selected.oldData.personalPhone
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Religion
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{
                  selected.oldData.religion ? selected.oldData.religion : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Blood Type
              </div>
              <div
                class="column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold"
              >
                {{
                  selected.oldData.bloodType ? selected.oldData.bloodType : '-'
                }}
              </div>
            </div>

            <div class="columns is-multiline">
              <div class="column is-12">
                <p class="has-text-weight-bold">Change To</p>
              </div>
              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                First Name
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'firstName'
                )}`"
              >
                {{
                  selected.newData.firstName ? selected.newData.firstName : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Middle Name
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'middleName'
                )} `"
              >
                {{
                  selected.newData.middleName
                    ? selected.newData.middleName
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Last Name
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'lastName'
                )}`"
              >
                {{
                  selected.newData.lastName ? selected.newData.lastName : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Personal Email
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'personalEmail'
                )}`"
              >
                {{
                  selected.newData.personalEmail
                    ? selected.newData.personalEmail
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Date of Birth
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'dateOfBirth'
                )}`"
              >
                {{
                  selected.newData.dateOfBirth
                    ? formatDate(selected.newData.dateOfBirth, 'll')
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Place of Birth
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'placeOfBirth'
                )}`"
              >
                {{
                  selected.newData.placeOfBirth
                    ? selected.newData.placeOfBirth
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Nationality
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'nationality'
                )}`"
              >
                {{
                  selected.newData.nationality
                    ? selected.newData.nationality
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Address
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'address'
                )}`"
              >
                {{ selected.newData.address ? selected.newData.address : '-' }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Current Address
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'currentAddress'
                )}`"
              >
                {{
                  selected.newData.currentAddress
                    ? selected.newData.currentAddress
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Gender
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'gender'
                )}`"
              >
                {{ selected.newData.gender ? selected.newData.gender : '-' }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Personal Phone
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'personalPhone'
                )}`"
              >
                {{
                  selected.newData.personalPhone
                    ? selected.newData.personalPhone
                    : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Religion
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'religion'
                )}`"
              >
                {{
                  selected.newData.religion ? selected.newData.religion : '-'
                }}
              </div>

              <div class="column is-6-desktop is-4-tablet is-12-mobile">
                Blood Type
              </div>
              <div
                :class="`column is-6-desktop is-8-tablet is-12-mobile has-text-weight-bold ${determineValueFontColor(
                  'bloodType'
                )}`"
              >
                {{
                  selected.newData.bloodType ? selected.newData.bloodType : '-'
                }}
              </div>
            </div>

            <div
              class="columns is-multiline"
              v-if="selected.status === 'waiting'"
            >
              <div class="column is-6-desktop is-6-tablet is-12-mobile">
                <b-button
                  class="is-grey"
                  @click="updateStatus('declined', selected.id)"
                  :disabled="isUpdating"
                >
                  Decline
                </b-button>
              </div>

              <div class="column is-6-desktop is-6-tablet is-12-mobile">
                <b-button
                  type="is-primary"
                  @click="updateStatus('approved', selected.id)"
                  :loading="isUpdating"
                >
                  Approve
                </b-button>
              </div>
            </div>
          </r-card>
        </b-sidebar>
      </template>
    </r-page>
  </div>
</template>

<script>
import ApiService from '../../../services/common/api.service'
import {
  determineBackgroundColorByStatus,
  determineFontColorByStatus,
  formatDate,
  showToast,
} from '../../../services/util'

export default {
  watch: {
    selected: function () {
      if (this.selected) {
        this.isSideBarOpen = true
      }
    },
  },
  data() {
    return {
      isSideBarOpen: false,

      sortField: 'updated_at',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      search: '',
      status: '',

      isLoadMore: false,
      perPage: 20,
      lastPage: 0,
      total: 0,
      page: 0,

      isLoadPersonalInfoList: false,

      selected: null,
      isUpdating: false,
      personalInfoList: [],
    }
  },
  methods: {
    onPageChange(page) {
      this.page = page
      this.loadPeopleDataList()
    },
    async updateStatus(status, id) {
      this.isUpdating = true
      try {
        await ApiService.put(
          `/api/space-roketin/employee-info/approval/${id}`,
          {
            status,
          }
        )
        if (status === 'approved') {
          showToast('Request approved', 'is-success', 'is-bottom')
        } else {
          showToast('Request declined', 'is-success', 'is-bottom')
        }

        // update status
        let temp = [...this.personalInfoList]
        let index = temp.findIndex((p) => p.id === id)
        if (index >= 0) {
          temp[index].status = status
          this.personalInfoList = temp
        }
      } catch (err) {
        console.log(err)
      }

      this.isUpdating = false
    },
    determineValueFontColor(key) {
      if (this.selected.oldData[key] !== this.selected.newData[key]) {
        return 'has-text-primary'
      }
      return ''
    },
    determineFontColorByStatus(status) {
      return determineFontColorByStatus(status)
    },
    determineBGColorByStatus(status) {
      return determineBackgroundColorByStatus(status)
    },
    formatDate(date, format) {
      return formatDate(date, format)
    },
    closeSidebar() {
      this.isSideBarOpen = false
      this.selected = null
    },
    async loadPersonalInfoList() {
      this.isLoadPersonalInfoList = true
      try {
        let response = await ApiService.get(
          '/api/space-roketin/employee-info/approval',
          {
            perPage: this.perPage,
            page: ++this.page,
            sortField: this.sortField,
            sortOrder: this.sortOrder,
            search: this.search,
            status: this.status,
          }
        )
        this.personalInfoList = [
          ...this.personalInfoList,
          ...response.data.data,
        ]
        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isLoadPersonalInfoList = false
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },
    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.personalInfoList = []
      await this.loadPersonalInfoList()
    },
    async searchIconClick() {
      await this.resetState()
    },
    async setEventListerScroll() {
      if (this.page < this.lastPage) {
        let myTable = document.querySelector('.my-table .table-wrapper')
        myTable.addEventListener('scroll', () => this.onScroll(myTable))
      }
    },
    onScroll(divElement) {
      let that = this
      divElement.addEventListener('scroll', async function () {
        const { scrollTop, scrollHeight, clientHeight } = divElement
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          if (that.page < that.lastPage) {
            that.isLoadMore = true
            await that.loadPersonalInfoList()
            that.isLoadMore = false
          }
        }
      })
    },
  },
  async mounted() {
    await this.loadPersonalInfoList()
    await this.setEventListerScroll()
  },
}
</script>

<style scoped lang="scss">
.status-container {
  width: 74px;
  text-transform: capitalize;
  padding: 3px 7px;
  border-radius: 10px;
  text-align: center;
}
</style>
